import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/analytics";

const config = {
  apiKey: "AIzaSyBLiVMDeMf42dSO5ZnhnYFkCJlD8fxHDLU",
  authDomain: "manejebem-app.firebaseapp.com",
  databaseURL: "https://manejebem-app.firebaseio.com",
  projectId: "manejebem-app",
  storageBucket: "manejebem-app.appspot.com",
  messagingSenderId: "249227108685",
  appId: "1:249227108685:web:5b6ec76ca65eb2fdcd5704",
  measurementId: "G-M68B8WYYC2",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
export const db = firebase.firestore();
export const firebaseAuth = firebase.auth;
export const storage = firebase.app().storage("gs://manejebem-app.appspot.com");
export const analytics = firebase.analytics();

export async function initFirebaseAnalytics(event_name, params) {
  console.log({
    event_name,
    params,
  });

  const firebaseAnalytics = await firebase
    .analytics()
    .logEvent(event_name, params);

  return firebaseAnalytics;
}

// ///////////////////////////////////////// TEST ///////////////////////////////////////////

// // import firebase from 'firebase';
// // // import { FireSQL } from 'firesql';
// // require("firebase/firestore");

// // const config = {
// //     apiKey: "AIzaSyBSqQOLzksvpozaRdtf1h7u74HUfDAz9bM",
// //     authDomain: "vibbra-da04a.firebaseapp.com",
// //     databaseURL: "https://vibbra-da04a.firebaseio.com",
// //     projectId: "vibbra-da04a",
// //     storageBucket: "vibbra-da04a.appspot.com",
// //     messagingSenderId: "821585043184",
// //     appId: "1:821585043184:web:a70b31240849eae5016c35"
// // }

// // if (!firebase.apps.length) {
// //     firebase.initializeApp(config);
// // }
// // export const db = firebase.firestore();
// // // export const fireSQL = new FireSQL(db);
// // export const firebaseAuth = firebase.auth;
// // export const fireStore = firebase.firestore();
// // export const firebaseNow = () => firebase.firestore.Timestamp.fromDate(new Date());
// // export const storage = firebase.app().storage("gs://manejebem-app.appspot.com");
